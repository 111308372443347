import {Link} from "react-router-dom";
import "./App.css";
import {Typography} from "antd";
const {Title} = Typography;

export default function Error() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "60vh",
        flexDirection: "column",
      }}>
      <img src={require("./images/error.png")} alt="错误" style={{width: 100, margin: 10}}></img>
      <Title>前面的区域，以后再来探索吧？</Title>
      {/* <div className='lead'>页面错误，请确认访问地址是否正确；禁止访问。</div> */}
      <div className="lead">
        网页已迁移至桌面端APP，请<Link to="/download">前往下载</Link>
      </div>
    </div>
  );
}
