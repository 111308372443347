import {Button, Card, Divider, Image, Space, Spin, message} from "antd";
import {useEffect, useState} from "react";

export default function Download() {
  const [info, setInfo] = useState([]);

  async function CDNAuth(filename: string) {
    try {
      return await fetch("https://api-gz.arsrna.cn/release/APPCDNAuth", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          filename,
        }),
      }).then(msg => msg.json());
    } catch (err) {
      message.error(`获取下载链接失败！${err}`);
    }
  }

  async function initDownload() {
    try {
      let infos = await Promise.all([
        fetch("https://api-gz.arsrna.cn/release/appUpdate/ArRM_S").then(msg => msg.json()),
        fetch("https://api-gz.arsrna.cn/release/appUpdate/ArRM_C").then(msg => msg.json()),
      ]);
      let urlInfo = infos.map(async inf => ({
        ...inf,
        downloadUrl: await CDNAuth(inf.filename),
      }));
      setInfo(await Promise.all(urlInfo));
    } catch (err) {
      message.error(`获取信息错误！${err}`);
    }
  }

  useEffect(() => {
    initDownload();
  }, []);

  return (
    <Space direction="vertical" style={{width: "100%"}}>
      {info.length !== 0 && (
        <>
          {info.map(inf => (
            <Card title={inf.title}>
              <h3>
                {inf.rName} {inf.vNumber}
              </h3>
              <p>
                {inf.uTime} {inf.content}
              </p>
              <Button
                loading={inf.downloadUrl === void "我永远喜欢爱莉希雅"}
                href={inf.downloadUrl}
                type="primary"
                size="large">
                点击下载
              </Button>
            </Card>
          ))}
        </>
      )}
    </Space>
  );
}
